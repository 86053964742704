import React, { useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrapTheme from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';

import { Button, Form, Modal } from 'react-bootstrap';

import { api } from 'lib/api';

import './root.scss';

// Source: https://coolors.co/palette/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1
const COLORS = [
  '#F94144',
  '#F3722C',
  '#F8961E',
  '#F9844A',
  '#F9C74F',
  '#90BE6D',
  '#43AA8B',
  '#4D908E',
  '#577590',
  '#277DA1',
]

function dateToString(date) {
  return date.toISOString().split('T')[0];
}

function isMobile() {
  return !(window.matchMedia("(min-width: 480px)").matches);
}

function transformLocation(location) {
  const end = new Date(location.endAt);
  end.setDate(end.getDate() + 1); // Add a day to make it display properly

  return {
    id: location.id,
    backgroundColor: COLORS[Number.parseInt(location.id) % COLORS.length],
    borderColor: COLORS[Number.parseInt(location.id) % COLORS.length],
    title: location.name,
    start: location.startAt,
    end: dateToString(end),
    allDay: true,
  };
}

async function fetchEvents(fetchInfo, onSuccess) {
  const response = await api.findAll('location');
  onSuccess(response.data.map(transformLocation));
}

function LocationModal({
  loading,
  location,
  onHide,
  onChangeEndAt,
  onChangeName,
  onChangeStartAt,
  onRemove,
  onSave,
}) {
  function onSubmit(event) {
    event.preventDefault();
    onSave();
  }

  return (
    <Modal show={!!location} onHide={loading ? undefined : onHide}>
      {location && (
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton={!loading}>
            <Modal.Title>{location?.id ? 'Update' : 'Add new'} location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="name">
              <Form.Label>Where are you headed?</Form.Label>
              <Form.Control
                disabled={loading}
                required
                type="text"
                value={location?.name}
                onChange={onChangeName}
              />
            </Form.Group>

            <Form.Group controlId="startAt">
              <Form.Label>When do you get there?</Form.Label>
              <Form.Control
                disabled={loading}
                required
                type="date"
                value={location?.startAt}
                onChange={onChangeStartAt}
              />
            </Form.Group>

            <Form.Group controlId="endAt">
              <Form.Label>When are you leaving there?</Form.Label>
              <Form.Control
                disabled={loading}
                required
                type="date"
                value={location?.endAt}
                onChange={onChangeEndAt}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} variant="secondary" onClick={onHide}>
              Close
            </Button>
            {
              location?.id && (
                <Button disabled={loading} variant="danger" onClick={onRemove}>
                  Remove
                </Button>
              )
            }
            <Button disabled={loading} variant="primary" type="submit">
              Save changes
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

export function Root() {
  const calendar = useRef();
  const [curLocation, setCurLocation] = useState();
  const [loading, setLoading] = useState(false);

  function onHide() {
    setCurLocation(undefined);
  }

  async function onRemove() {
    if (window.confirm('Are you sure you want to delete this?')) {
      setLoading(true);
      try {
        await api.destroy('location', curLocation.id);
        calendar.current.getApi().getEventById(curLocation.id).remove();
        onHide();
      } finally {
        setLoading(false);
      }
    }
  }

  async function onSave() {
    let location;
    setLoading(true);
    try {
      if (curLocation.id) {
        location = await api.update('location', { ...curLocation });
        calendar.current.getApi().getEventById(location.data.id).remove();
        calendar.current.getApi().addEvent(transformLocation(location.data));
      } else {
        location = await api.create('location', { ...curLocation });
        calendar.current.getApi().addEvent(transformLocation(location.data));
      }
      onHide();
    } finally {
      setLoading(false);
    }
  }

  async function onEventClick(eventInfo) {
    setLoading(true);
    try {
      const location = await api.find('location', eventInfo.event.id);
      setCurLocation(location.data);
    } finally {
      setLoading(false);
    }
  }

  function onChangeName(event) {
    setCurLocation(location => ({ ...location, name: event.target.value }));
  }

  function onChangeStartAt(event) {
    setCurLocation(location => ({ ...location, startAt: event.target.value }));
  }

  function onChangeEndAt(event) {
    setCurLocation(location => ({ ...location, endAt: event.target.value }));
  }

  function onNewLocation({ name = '', startAt = '', endAt = '' }) {
    setCurLocation({ name, startAt, endAt });
  }

  return (
    <>
      <LocationModal
        loading={loading}
        location={curLocation}
        onChangeEndAt={onChangeEndAt}
        onChangeName={onChangeName}
        onChangeStartAt={onChangeStartAt}
        onHide={onHide}
        onRemove={onRemove}
        onSave={onSave}
      />
      <FullCalendar
        customButtons={{
          addLocation: { text: 'Add location', click: onNewLocation }
        }}
        dateClick={(info) => { onNewLocation({ startAt: info.dateStr }); }}
        eventClick={onEventClick}
        events={fetchEvents}
        expandRows
        headerToolbar={{ right: 'addLocation today prev,next'}}
        height="auto"
        initialView={isMobile() ? "listWeek" : "dayGridMonth" }
        plugins={[
          dayGridPlugin,
          listPlugin,
          bootstrapTheme,
          interactionPlugin,
        ]}
        ref={calendar}
        selectable
        select={
          ({ start, end }) => {
            end.setDate(end.getDate() - 1);
            onNewLocation({
              startAt: dateToString(start),
              endAt: dateToString(end),
            });
          }
        }
        themeSystem="bootstrap5"
      />
    </>
  );
}
