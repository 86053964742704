import React from 'react';
import { useRouteError } from 'react-router-dom';

export function Error() {
  const error = useRouteError();

  return (
    <>
      <h1>Sorry we're no sure how to handle that</h1>
      <div>
        <p>Got statusText: {error.statusText}</p>
        <p>Got message: {error.message}</p>
      </div>
    </>
  );
}
