import JsonApi from 'devour-client';

const api = new JsonApi({ apiUrl: '/api' });

api.define(
  'location', {
    name: '',
    endAt: '',
    startAt: '',
  },
);

api.headers['Accept'] = 'application/json';
api.headers['Content-Type'] = 'application/json';
api.headers['X-Requested-With'] = 'XMLHTTPRequest';
api.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export { api }
